.list-days__button-change-week {
    border: none;
    width: 35px;
    padding: 0;
    background-color: transparent;
}

.list-days__button-change-week:nth-child(1) {
    margin-right: 11px;
}

@keyframes move {
    0% {
        width: 35px;
        height: 35px;
    }

    50% {
        width: 40px;
        height: 40px;
    }

    100% {
        width: 35px;
        height: 35px;
    }
}

.list-days__button-change-week :hover {
    cursor: pointer;
    animation: move 1s;
}