body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@import url(./styles/normalize.css);

@import url(./fonts/inter.css);
@import url(./fonts/bebas-regular.css);
@import url(./fonts/et-book.css);
@import url(./fonts/Cormorant_Garamond/Cormorant_Garamond.css);
@import url(./fonts/appleFonts.css);
@import url(./fonts/instagramm.css);
@import url(./fonts/Montserrat/Montserrat.css);

@import url(./components/App/App.css);

@import url(./components/MainPage/style/mainPage.css);
@import url(./components/MainPage/style/mainPage__link-container.css);
@import url(./components/MainPage/style/mainPage__link-page.css);
@import url(./components/MainPage/style/mainPage__link-page_Dark.css);
@import url(./components/MainPage/style/mainPage__link-page__description.css);

@import url(./components/OneOfMainPage/style/oneOfMainPage.css);
@import url(./components/OneOfMainPage/style/oneOfMainPage__title.css);
@import url(./components/OneOfMainPage/style/oneOfMainPage__title-dark.css);
@import url(./components/OneOfMainPage/style/oneOfMainPage__title-flex.css);
@import url(./components/OneOfMainPage/style/oneOfMainPage__backgroundColor-dark.css);
@import url(./components/OneOfMainPage/style/oneOfMainPage__backgroundColor-flex.css);
@import url(./components/OneOfMainPage/style/oneOfMainPage__subtitle.css);
@import url(./components/OneOfMainPage/style/oneOfMainPage__subtitle-dark.css);
@import url(./components/OneOfMainPage/style/oneOfMainPage__backgroundColor-flex.css);
@import url(./components/OneOfMainPage/style/oneOfMainPage__button.css);

@import url(./components/Flex/style/flex.css);
@import url(./components/Flex/style/flex__title.css);
@import url(./components/Flex/style/flex__header.css);
@import url(./components/Flex/style/flex__button.css);
@import url(./components/Flex/style/flex__color-text.css);
@import url(./components/Flex/style/flex__title-color-text.css);
@import url(./components/Flex/style/flex__button-day-weeks.css);

@import url(./components/TimeTable/style/timetable.css);
@import url(./components/TimeTable/style/timetable__title.css);
@import url(./components/TimeTable/style/timetable__list.css);
@import url(./components/TimeTable/style/timetable__list-element.css);
@import url(./components/TimeTable/style/timetable__element.css);
@import url(./components/TimeTable/style/timetable__number.css);
@import url(./components/TimeTable/style/timetable__text.css);
@import url(./components/TimeTable/style/timetable__text-no-record.css);
@import url(./components/TimeTable/style/timetable__element_dateTrain.css);
@import url(./components/TimeTable/style/timetable__history-list-text-style.css);
@import url(./components/TimeTable/style/timetable__title_calendar.css);

@import url(./components/DayList/style/list-days.css);
@import url(./components/DayList/style/list-days__button-change-week.css);
@import url(./components/DayList/style/list-days__button-change-week_backround-color_flex.css);
@import url(./components/DayList/style/list-days__day-container.css);
@import url(./components/DayList/style/list-days.css);
@import url(./components/DayList/style/list-days__day.css);
@import url(./components/DayList/style/list-days__day_active.css);
@import url(./components/DayList/style/list-days__day-date.css);
@import url(./components/DayList/style/list-days__day-date-style-text-flex.css);

@import url(./components/PageStudio/style/page/page__subscription-information.css);
@import url(./components/PageStudio/style/page/page__button.css);
@import url(./components/PageStudio/style/page/page__text.css);
@import url(./components/PageStudio/style/page/page.css);
@import url(./components/PageStudio/style/page/page__header.css);
@import url(./components/PageStudio/style/page/page__title.css);
@import url(./components/PageStudio/style/page__subtitle.css);
@import url(./components/PageStudio/style/page/page__title-container.css);
@import url(./components/PageStudio/style/page/page__title-subtitle-container.css);
@import url(./components/PageStudio/style/page/page__container-buttons.css);
@import url(./components/PageStudio/style/page/page__imageWA.css);
@import url(./components/PageStudio/style/page/page__imageWA-button.css);
@import url(./components/PageStudio/style/page/page__footer.css);
@import url(./components/PageStudio/style/page/page__freeze-button.css);
@import url(./components/PageStudio/style/page/page__imageInfoTab.css);

@import url(./components/AdminPage/style/admin-page.css);
@import url(./components/AdminPage/style/admin-page__header.css);
@import url(./components/AdminPage/style/admin-page__title_active.css);
@import url(./components/AdminPage/style/admin-page__title.css);
@import url(./components/AdminPage/style/admin-page__list-comand.css);
@import url(./components/AdminPage/style/admin-page__list-comand-button.css);
@import url(./components/AdminPage/style/admin-page__select-container.css);
@import url(./components/AdminPage/style/admin-page__header-link-container.css);
@import url(./components/AdminPage/style/admin-page__header-boarder.css);
@import url(./components/AdminPage/style/admin-page__button-img.css);
@import url(./components/AdminPage/style/admin-page__list-comand-button_personal.css);
@import url(./components/AdminPage/style/admin-page__list-comand-button_groupe.css);
@import url(./components/AdminPage/style/admin-page__list-comand-button_waiting-list.css);
@import url(./components/AdminPage/style/admin-page__training-list-buttons-container.css);
@import url(./components/AdminPage/style/admin-page__list-comand-button_hystory-abonements.css);
@import url(./components/AdminPage/style/admin-page__footer__container.css);
@import url(./components/AdminPage/style/admin-page__footer__container-element.css);
@import url(./components/AdminPage/style/admin-page__button-qr.css);

@import url(./components/SelectList/style/select-list__select.css);
@import url(./components/SelectList/style/select-list__container.css);
@import url(./components/SelectList//style/select-container__date-selection.css);
@import url(./components/SelectList/style/select-list__button-submit.css);

@import url(./components/ClientsTable/style/clients-table__form.css);
@import url(./components/ClientsTable/style/clients-table__form__title.css);
@import url(./components/ClientsTable/style/clients-table__form__input.css);
@import url(./components/ClientsTable/style/clients-table__form__button.css);
@import url(./components/ClientsTable/style/clientstable-text.css);
@import url(./components/ClientsTable/style/clients-table__form__input_flex.css);
@import url(./components/ClientsTable/style/clients-table_background.css);

@import url(./components/InfoTooltip/style/info-tooltip.css);
@import url(./components/InfoTooltip/style/info-tooltip__close-button.css);
@import url(./components/InfoTooltip/style/info-tooltip__container.css);
@import url(./components/InfoTooltip/style/info-tooltip__overlay.css);
@import url(./components/InfoTooltip/style/info-tooltip_show.css);
@import url(./components/InfoTooltip/style/tooltip__massage.css);
@import url(./components/InfoTooltip/style/tooltip__button.css);
@import url(./components/InfoTooltip/style/tooltip__buttons-container.css);
@import url(./components/InfoTooltip/style/tooltip__buttons-style.css);

@import url(./components/FormAdd/style/formadd.css);
@import url(./components/FormAdd/style/formadd_show.css);
@import url(./components/FormAdd/style/formadd__content.css);
@import url(./components/FormAdd/style/formadd__close-button.css);
@import url(./components/FormAdd/style/formadd__form.css);
@import url(./components/FormAdd/style/formadd__title.css);
@import url(./components/FormAdd/style/formadd__set.css);
@import url(./components/FormAdd/style/formadd__button.css);
@import url(./components/FormAdd/style/formadd__button_flex.css);
@import url(./components/FormAdd/style/formadd__close-button_hide.css);
@import url(./components/FormAdd/style/formadd__input-conainer.css);
@import url(./components/FormAdd/style/formadd__text-error.css);
@import url(./components/FormAdd/style/formadd__input.css);
@import url(./components/FormAdd/style/formadd_abonement.css);
@import url(./components/FormAdd/style/formadd__form_abonement.css);
@import url(./components/FormAdd/style/formadd__checkbox.css);
@import url(./components/FormAdd/style/formadd__checkbox-button.css);
@import url(./components/FormAdd/style/formadd__checkbox-button_flex.css);
@import url(./components/FormAdd/style/formadd__checkbox-button_on.css);
@import url(./components/FormAdd/style/formadd__checkbox-button_on_flex.css);
@import url(./components/FormAdd/style/formadd__checkbox-button_off.css);
@import url(./components/FormAdd/style/formadd__checkbox-button_off_flex.css);
@import url(./components/FormAdd/style/formadd__checkbox-button-name.css);
@import url(./components/FormAdd/style/formadd__input-select.css);
@import url(./components/FormAdd/style/formadd__select-option.css);
@import url(./components/FormAdd/style/formadd__text-input-info.css);
@import url(./components/FormAdd/style/formadd__checkbox-button-name-two.css);
@import url(./components/FormAdd/style/formadd__checkbox_super-admin.css);
@import url(./components/FormAdd/style/formadd__button-under-text.css);
@import url(./components/FormAdd/style/formadd__input-plaiceholder-container.css);
@import url(./components/FormAdd/style/formadd__input-text.css);
@import url(./components/FormAdd/style/formadd__input-time-and-date.css);

@import url(./components/FormAddFreeze/style/formadd-freeze.css);
@import url(./components/FormAddFreeze/style/formadd__input-conainer_freeze.css);

@import url(./components/TrainingHistory/style/formadd__training-history.css);

@import url(./components/AboutClientPage/style/about-client-page.css);
@import url(./components/AboutClientPage/style/about-client-page-title.css);
@import url(./components/AboutClientPage/style/about-client-page__client-info.css);
@import url(./components/AboutClientPage/style/about-client-page__button.css);
@import url(./components/AboutClientPage/style/about-client-page__button_flex.css);
@import url(./components/AboutClientPage/style/about-client-page__button-elements.css);
@import url(./components/AboutClientPage/style/about-client-page__button_active.css);
@import url(./components/AboutClientPage/style/about-client-page__text.css);
@import url(./components/AboutClientPage/style/about-client-page__text_name-abonement.css);
@import url(./components/AboutClientPage/style/about-client-page__text-abonement-container.css);
@import url(./components/AboutClientPage/style/about-client-page__abonement-indicator.css);
@import url(./components/AboutClientPage/style/about-client-page__abonement-indicator_true.css);
@import url(./components/AboutClientPage/style/about-client-page__abonement-indicator_false.css);
@import url(./components/AboutClientPage/style/about-client-page__button_add-personal.css);
@import url(./components/AboutClientPage/style/about-client-page__abonement-indicator_pre-false.css);

@import url(./components/CommentsTable/style/comments-table__textarea.css);

@import url(./components/ClientInfo/style/client-info__container.css);
@import url(./components/ClientInfo/style/client-info__container-indicator.css);
@import url(./components/ClientInfo/style/client-info__indicator_client-page.css);
@import url(./components/ClientInfo/style/client-info__container-info.css);
@import url(./components/ClientInfo/style/client-info__quantityVisits.css);
@import url(./components/ClientInfo/style/client-info__container-main.css);
@import url(./components/ClientInfo/style/client-info__container-main-info.css);
@import url(./components/ClientInfo/style/client-info__container-main-quantityVisits.css);
@import url(./components/ClientInfo/style/client-info__quantityVisits_groupe.css);
@import url(./components/ClientInfo/style/client-info__button-abonements.css);
@import url(./components/ClientInfo/style/client-info__container-indicator-abonement.css);
@import url(./components/ClientInfo/style/client-info__container-indicator-abonement_admin.css);

@import url(./components/SearchForm/style/search-form.css);
@import url(./components/SearchForm/style/search-form__border.css);
@import url(./components/SearchForm/style/search-form__button-name.css);
@import url(./components/SearchForm/style/search-form__button.css);
@import url(./components/SearchForm/style/search-form__button_off.css);
@import url(./components/SearchForm/style/search-form__button_on.css);
@import url(./components/SearchForm/style/search-form__checkbox.css);
@import url(./components/SearchForm/style/search-form__container-input.css);
@import url(./components/SearchForm/style/search-form__container.css);
@import url(./components/SearchForm/style/search-form__icon.css);
@import url(./components/SearchForm/style/search-form__img.css);
@import url(./components/SearchForm/style/search-form__input-button.css);
@import url(./components/SearchForm/style/search-form__input.css);
@import url(./components/SearchForm/style/search-form__input-button_on-phone.css);

@import url(./components/TrainingCalendar.js/style/training-calendar.css);
@import url(./components/TrainingCalendar.js/style/trainig-calendar-container.css);
@import url(./components/TrainingCalendar.js/style/training-calendar__hide.css);

@import url(./components/InfoTab/style/infotab.css);
@import url(./components/InfoTab/style/infotab__button-close.css);
@import url(./components/InfoTab/style/infotab__button.css);
@import url(./components/InfoTab/style/infotab__button_border-show.css);
@import url(./components/InfoTab/style/infotab_visible.css);
@import url(./components/InfoTab/style/infotab__description.css);
@import url(./components/InfoTab/style/infotab__description-title.css);
@import url(./components/InfoTab/style/infotab__description_subdescription.css);

@import url(./components/TrainerPage/style/trainer-page__info-container.css);
@import url(./components/TrainerPage/style/trainer-page__info.css);
@import url(./components/TrainerPage/style/trainer-page__info-text.css);
@import url(./components/TrainerPage/style/trainer-page__info-text_align-center.css);
@import url(./components/TrainerPage/style/trainer-page__info-text_align-right.css);
@import url(./components/TrainerPage/style/trainer-page__role.css);

@import url(./components/TrainersList/style/trainer-list__trainer-info.css);

@import url(./components/AbonementsInfo/style/abonements-info__table.css);
@import url(./components/AbonementsInfo/style/abonements-info__table-column.css);
@import url(./components/AbonementsInfo/style/abonements-info__table-column-text.css);
@import url(./components/AbonementsInfo/style/abonements-info__text-align-left.css);
@import url(./components/AbonementsInfo/style/abonements-info__text-align-right.css);

@import url(./components/AbonementsInfo/style/abonements-info__title.css);
@import url(./components/AbonementsInfo/style/abonements-info__title-column.css);

@import url(./components/AbonementsList/style/abonements-list.css);
@import url(./components/AbonementsList/style/abonements-list__container.css);
@import url(./components/AbonementsList/style/abonements-list__container-element.css);
@import url(./components/AbonementsList/style/abonements-list__text.css);

@import url(./components/SelectMonthYear/style/selectMonthYear.css);

@import url(./components/Dark/style/dark.css);
@import url(./components/Dark/style/dark__color-text.css);
@import url(./components/Dark/style/dark__buttons.css);
@import url(./components/Dark/style/dark__button-day-weeks.css);
@import url(./components/Dark/style/dark__day-date-text.css);
@import url(./components/Dark/style/dark__footer.css);
@import url(./components/Dark/style/dark__search-form.css);
@import url(./components/Dark/style/dark__checkbox.css);
@import url(./components/Dark/style/dark__form.css);

@import url(./components/NotFoundPage//style/not-found-page.css);
@import url(./components/NotFoundPage/style/not-found-page__content.css);
@import url(./components/NotFoundPage/style/not-found-page__link.css);
@import url(./components/NotFoundPage/style/not-found-page__subtitle.css);
@import url(./components/NotFoundPage/style/not-found-page__title.css);

@import url(./components/NeedBrowserUpdatePage/style/browser-update__title.css);

@import url(./components/SignIn/style/signin.css);
@import url(./components/SignIn/style/signin__form.css);

@import url(./components/LoadingPage/style/page-loading.css);
@import url(./components/LoadingPage/style/page-loading__name.css);
@import url(./components/LoadingPage/style/page-loading__name-color.css);
@import url(./components/LoadingPage/style/page-loading__name-dark.css);

@import url(./components/QRCodeReader/style/qr-reader.css);
@import url(./components/QRCodeReader/style/qr-reader__container.css);
@import url(./components/QRCodeReader/style/qr-reader__text.css);
@import url(./components/QRCodeReader/style/qr-reader__container-buttons.css);
@import url(./components/QRCodeReader/style/qr-reader__button.css);
@import url(./components/QRCodeReader/style/qr-reader__close-button.css);

@import url(./components/TrainingList/style/training-list__img-button.css);

@import url(./components/WindowClientsControl/style/window-clients-control.css);
@import url(./components/WindowClientsControl/style/window-clients-control__close-button.css);
@import url(./components/WindowClientsControl/style/window-clients-control__buttons-container.css);
@import url(./components/WindowClientsControl/style/window-clients-control__button.css);
@import url(./components/WindowClientsControl/style/window-clients-control__description.css);

@import url(./components/SearchFormForAddTraining/style/search-form-for-add-training.css);

@import url(./components/ClientInfo/style/clients-info__add-training.css);

@import url(./components/FormAdd/style/formadd__title-clients.css);