.timetable__title {
    margin: 0;
    font-weight: 900;
    font-family: 'Montserrat-SemiBold','Inter';
    letter-spacing: 1px;
    font-size: 23px;
    text-align: center;
    padding-top: 3vh;
}

@media screen and (max-width: 599px) {
    .timetable__title {
        letter-spacing: 0px;
        font-size: 15px;
        padding-top: 40px;
    }
}